<template>
    <div>
        <div class="ll-box">
            <!-- breadcrumb -->
            <div class="breadcrumb-top">
                <b-breadcrumb class="">
                    <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon
                        icon="HomeIcon"
                        size="16"
                    />
                    </b-breadcrumb-item>
                    <b-breadcrumb-item>
                    {{ $t('Delivery Order') }}
                    </b-breadcrumb-item>
                    <b-breadcrumb-item active >
                    {{ $t('COD FOD List') }}
                    </b-breadcrumb-item>
                </b-breadcrumb>
            </div>
        </div>
        <!-- Filters -->
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col md="3">
                        {{ $t('Create Date') }}
                        <flat-pickr
                        class="form-control ll-fpickr"
                        :config="dateConfig"
                        v-model="time"
                        @on-change="onChange"
                        />
                    </b-col>
                    <b-col md="3">
                        {{ $t('Tracking Number') }}
                        <b-input-group>
                            <b-form-input
                                class="searchInput"
                                v-model="trackingID"
                                placeholder=""
                            />
                            <!--  -->
                            <b-input-group-prepend
                                is-text
                                class="ll-searchBtn"
                                @click="searchBtn"
                                :style="{'cursor': loadingList ? 'no-drop' : 'pointer'}"
                                :disabled="loadingList"
                                >
                                <feather-icon
                                icon="SearchIcon"
                                class="mr-25"
                                size="18"
                                />
                            </b-input-group-prepend>
                        </b-input-group>
                    </b-col>
                    <b-col md="4">
                         {{ $t('Service Party') }}
                         <div style="display:flex">
                             <b-form-checkbox
                            class="ll-right"
                            v-model="homeDelivery"
                            @change="checkboxChange('KEHK',homeDelivery)"
                            >
                            {{ $t('Home Delivery') }}
                            </b-form-checkbox>
                            <b-form-checkbox
                            class="ll-right"
                            v-model="mtrstore"
                             @change="checkboxChange('MTRSTORE',mtrstore)"
                            >
                            {{ $t('MTRSTORE') }}
                            </b-form-checkbox>
                            <b-form-checkbox
                            class="ll-right"
                            v-model="kelocker"
                             @change="checkboxChange('KELOCKER',kelocker)"
                            >
                            {{ $t('KELOCKER') }}
                            </b-form-checkbox>
                         </div>
                        
                    </b-col>
                    <b-col cols="2" class="d-flex justify-content-end">
                        <b-button
                        variant="warning"
                        class="ex-bt"
                        @click="exportCod"
                        >
                            Export
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <!-- table -->
        <b-table
        ref="selectableTable"
        class="position-relative ll-table"
        :items="rows"
        :fields="columns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :striped="true"
        :hover="true"
        selectable
        @row-selected="onRowSelected"
        :select-mode="selectMode"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        v-permission="[143]"
        :sticky-header="true"
        :busy="isBusy"
        >
            <template v-slot:head(selected)="data">
                <b-form-checkbox @input="selectAllRowsData">
                    </b-form-checkbox>
            </template>
            <template v-slot:cell(selected)="{rowSelected, index}">
                <b-form-checkbox :checked="rowSelected?true:false" @change="selectRowData(rowSelected, index)">
                </b-form-checkbox>
            </template>
            <template #cell(action)="row">
                <feather-icon
                    v-show="row.item.trackingNumber !== null"
                    :id="`${row.item.trackingNumber}`"
                    icon="EditIcon"
                    size="16"
                    class="mx-1"
                    @click="editSKUDetail(row.item)"
                    v-permission="[140]"
                />
            </template>
        </b-table>
        <!-- <el-table
        ref="multipleTable"
        :data="rows"
        tooltip-effect="dark"
        style="width: 100%"
        :height="window.height - 270"
        @selection-change="handleSelection"
        @select-all="handleSelectAll"
        class="ll-eltable"
        v-permission="[140]"
        >
            <el-table-column
                type="selection"
                width="45">
            </el-table-column>
            <template v-for="(item, index) in tableHeaders">
                <el-table-column
                v-if="item.prop == 'customerName'"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :width="400"
                sortable
                >
                </el-table-column>
                <el-table-column
                v-if="item.prop == 'orderNumber'"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :min-width="170"
                sortable
                >
                </el-table-column>
                <el-table-column
                v-if="item.prop == 'trackingNumber'"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :min-width="170"
                sortable
                >
                </el-table-column>
                <el-table-column
                v-if="item.prop == 'codValue'"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :width="100"
                sortable
                >
                </el-table-column>
                <el-table-column
                v-if="item.prop == 'fodValue'"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :width="100"
                sortable
                >
                </el-table-column>
                <el-table-column
                v-if="item.prop == 'collectedDate'"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :width="160"
                sortable
                >
                </el-table-column>
                <el-table-column
                v-if="item.prop == 'collectedStatus'"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :min-width="100"
                sortable
                >
                </el-table-column>
                <el-table-column
                v-if="item.prop == 'serviceParty'"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :width="170"
                sortable
                >
                </el-table-column>
                <el-table-column
                v-if="item.prop == 'orderReceiveData'"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :min-width="160"
                sortable
                >
                </el-table-column>
                <el-table-column
                v-if="item.label == 'Action'"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :width="100"
                sortable
                >
                <template slot-scope="scope" align="center">
                    <feather-icon
                        v-show="scope.row.trackingNumber !== null"
                        :id="`${scope.row.trackingNumber}`"
                        icon="EditIcon"
                        size="16"
                        class="mx-1"
                        @click="editSKUDetail(scope.row)"
                        v-permission="[140]"
                    />
                </template>
                </el-table-column>
            </template>
        </el-table> -->
        <!-- show pages -->
        <div class="d-flex justify-content-between flex-wrap ll-p">
            <div class="d-flex align-items-center mb-0">
                <span class="text-nowrap ">
                Showing 1 to
                </span>
                <b-form-select
                v-model="size"
                :options="pageOptions"
                class="mx-1"
                @input="handlePageChange"
                />
                <span class="text-nowrap"> of {{ totalSize }} entries </span>
            </div>
            <div>
                <b-pagination
                v-model="page"
                :total-rows="totalSize"
                :per-page="size"
                first-number
                last-number
                class="mb-0 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="handleChangePage"
                >
                <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
            </div>
        </div>
        <b-modal
        id="modal-edit"
        cancel-variant="outline-secondary"
        ok-title=""
        centered
        :title="title"
        size="sm"
        v-model="show"
        hide-footer
        >
            <validation-observer ref="codFodValue">
                <b-form-group
                    label=""
                    label-for="oldCodValue"
                >
                    <!-- <validation-provider
                    #default="{ errors }"
                    name="oldCodValue"
                    rules="required"
                    > -->
                    <label class="ll-boldText">{{ $t('Original COD Amount') }}</label>
                    <b-form-input v-model="query.oldCodValue" type="number" readonly style="width:100%" />
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    <!-- </validation-provider> -->
                </b-form-group>
                <b-form-group
                    label=""
                    label-for="codValue"
                >
                    <validation-provider
                    #default="{ errors }"
                    name="codValue"
                    rules="required"
                    >
                    <label class="ll-boldText">{{ $t('New COD Amount') }}</label>
                    <b-form-input v-model="query.codValue" type="number" style="width:100%" />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group
                    label=""
                    label-for="oldFodValue"
                >
                    <!-- <validation-provider
                    #default="{ errors }"
                    name="oldFodValue"
                    rules="required"
                    > -->
                    <label class="ll-boldText">{{ $t('Original FOD Amount') }}</label>
                    <b-form-input v-model="query.oldFodValue" type="number" readonly style="width:100%" />
                    <!-- <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider> -->
                </b-form-group>
                <b-form-group
                    label=""
                    label-for="fodValue"
                >
                    <validation-provider
                    #default="{ errors }"
                    name="fodValue"
                    rules="required"
                    >
                    <label class="ll-boldText">{{ $t('New FOD Amount') }}</label>
                    <b-form-input v-model="query.fodValue" type="number" style="width:100%" />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </validation-observer>
            <b-button
                size="m"
                variant="outline-primary"
                style="background-color: #ed6d01;color:#fff;margin-left:75%;"
                 @click.prevent="validationForm"
            >
                <span class="mr-25 align-middle">Update</span>
            </b-button>
        </b-modal>
    </div>
</template>
<script>
import {
  BPagination, BFormSelect, BButton, BTooltip,
  BCard, BCardBody, BTable, BRow, BCol, BFormInput, BFormGroup, BSidebar,
  BInputGroupPrepend,
  BInputGroup,BFormCheckbox,
  BBreadcrumb,BModal,VBModal,
  BBreadcrumbItem, BBadge,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import permission from '@core/directives/permission/index.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        BPagination,
        BFormSelect,
        BButton,
        BTooltip,
        BCard,
        BCardBody,
        BTable,
        BRow,
        BCol,
        BFormCheckbox,
        flatPickr,
        BFormGroup,
        BSidebar,
        BInputGroupPrepend,
        ToastificationContent,
        BInputGroup,
        BBreadcrumb,
        BBreadcrumbItem,
        BBadge,
        BFormInput,
        BModal,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        'b-modal': VBModal,
        permission,
    },
    data(){
        return{
            selectMode: 'multi',
            required,
            window: {
                width: 0,
                height: 0
            },
            homeDelivery:true,
            mtrstore:true,
            kelocker:true,
            trackingID:'',
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            pageOptions: [50, 100, 200],
            page: null, // 当前页码
            size: 15, // 每页个数
            totalSize: null, // 总个数
            totalPage: null, // 总页数
            show:false,
            query:{},
            windowWidth: document.documentElement.clientWidth,  //屏幕宽度
            windowHeight: document.documentElement.clientHeight,   //屏幕高度
            height: document.documentElement.clientHeight*0.67,
            time:'',
            loadingList: false,
            tableHeaders: [
                { prop: 'customerName', label: this.$t('Customer'), sortable: true },
                { prop: 'orderNumber', label: this.$t('Order Number'), sortable: true },
                { prop: 'trackingNumber', label: this.$t('Tracking Number'), sortable: true },
                { prop: 'codValue', label: this.$t('COD'), sortable: true },
                { prop: 'fodValue', label: this.$t('FOD'), sortable: true },
                { prop: 'collectedDate', label: this.$t('Collected Date'), sortable: true },
                { prop: 'collectedStatus', label: this.$t('Collected Status'), sortable: true },
                { prop: 'serviceParty', label: this.$t('Service Party'), sortable: true },
                { prop: 'orderReceiveData', label: this.$t('Order Received Date'), sortable: true },
                { prop: '', label: this.$t('Action'), class: 'll-action' },
            ],
            dateConfig: {
                mode: 'range',
                defaultDate: [(new Date).setDate((new Date).getDate() - 5), new Date()],
            },
            rows: [],
            columns:[
                { key: 'selected', label: 'selected'},
                { key: 'customerName', label: this.$t('Customer'),thStyle: { width: "400px" }, sortable: true },
                { key: 'orderNumber', label: this.$t('Order Number'), sortable: true },
                { key: 'trackingNumber', label: this.$t('Tracking Number'), sortable: true },
                { key: 'codValue', label: this.$t('COD'), sortable: true },
                { key: 'fodValue', label: this.$t('FOD'), sortable: true },
                { key: 'collectedDate', label: this.$t('Collected Date'), sortable: true },
                { key: 'collectedStatus', label: this.$t('Collected Status'), sortable: true },
                { key: 'serviceParty', label: this.$t('Service Party'), sortable: true },
                { key: 'orderReceiveData', label: this.$t('Order Received Date'), sortable: true },
                { key: 'action', label: this.$t('Action'), class: 'll-action' },
            ],
            status:'',
            isBusy:false,
            condition: {
                page: '1',
                size: '50',
                serviceParty:['KELOCKER','KEHK','MTRSTORE']
            },
            title:'',
            selectAll:false,
            trackingNumberList:[],
        }
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    mounted(){
        this.getList()
        window.onresize = () => {
        return (() => {
            window.fullHeight = document.documentElement.clientHeight
            window.fullWidth = document.documentElement.clientWidth
            this.windowHeight = window.fullHeight
            this.windowWidth = window.fullWidth
        })()
        }
    },
    methods:{
        checkboxChange(val,type){
            if(type == true){
                this.condition.serviceParty.push(val)
            }else{
                let array = this.condition.serviceParty
                for (let i = 0; i < array.length; i++) {
                   if(array[i].indexOf(val)>-1){
                       array.splice(i,1)
                   }
                }
            }
            this.queryList()
        },
        editSKUDetail(data){
            this.show = true
            this.query = {
                oldFodValue : data.fodValue,
                oldCodValue :data.codValue,
                tkID:data.trackingNumber
            }
        },
        onRowSelected(items) {
            const arr = []
            items.map(i=>{
                arr.push(i.trackingNumber)
            })
            this.trackingNumberList= arr
        },
        selectRowData(rowSelected, index){
            if(rowSelected == false){
                this.$refs.selectableTable.selectRow(index)
            }else{
                this.$refs.selectableTable.unselectRow(index)
            }
            },
        selectAllRowsData(val) {
            this.selectAll = val ? true : false
            if (val == true) {
            this.$refs.selectableTable.selectAllRows()
            }
            if(val == false){
            this.$refs.selectableTable.clearSelected()
            }
            
        },
        searchBtn(){
            this.condition.trackingIDs = this.trackingID.trim().split(/[(\r\n)\r\n]+/)
            if(this.condition.trackingIDs.length == 1 && this.condition.trackingIDs[0] == ''){
                this.condition.trackingIDs = null
            }
            this.queryList()
        },
        // 查询列表
        queryList() {
            this.loadingList = true
            this.isBusy= true
            let obj = {}
            obj.jobKesByPage = this.condition
            this.$http.post('/codfod/search', obj).then(res => {
                const info = res.data.data
                this.rows = info.content
                this.page = info.page
                this.size = info.size
                this.totalSize = info.totalSize
                this.totalPage = info.totalPage
                this.loadingList = false
                this.isBusy = false
            }).catch(error => console.log(error))
        },
        // 首次查询
        getList() {
            this.dateConfig.dateFormat = 'Y-m-d'
            this.dateFormats()
            this.queryList()
        },
        validationForm(){
            this.$refs.codFodValue.validate().then(success => {
                if (success) {
                    console.info(this.query.tkID,'0--')
                    this.$http.post('codfod/updateCODFOD', this.query).then(res => {
                        if (res.data.code === '200') {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                title: 'Update Success',
                                icon: 'CheckIcon',
                                variant: 'success',
                                },
                            })
                            this.show = false
                            this.getList()
                        } else {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                title: res.data.message,
                                icon: 'XIcon',
                                variant: 'danger',
                                },
                            })
                        }
                    })
                }
            })
        },
        onChange(selectedDates, dateStr, instance) {
            if (selectedDates.length == 1) {
                let later = new Date(selectedDates[0].getTime() + 86400000 * 31)
                instance.config.minDate = selectedDates[0].toUTCString()
                instance.config.maxDate = later.toUTCString()
            } else if (selectedDates.length == 2) {
                instance.config.minDate = undefined
                instance.config.maxDate = undefined
                this.condition.shipmentDateFrom = this.formatDate(selectedDates[0]) + ' 00:00:00'
                this.condition.shipmentDateTo = this.formatDate(selectedDates[1]) + ' 23:59:59'
                this.eadDate = ""
                this.queryList()
            }
        },
        exportCod(){
            if (this.trackingNumberList.length <= 0) {
                this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Please check the data',
                    icon: 'XIcon',
                    variant: 'danger',
                },
                })
                return
            }
            let obj = {}
            if (this.selectAll) {
                obj.from = '2022-03-07 00:00:00' // 日期不能为空，假数据
                obj.to = '2022-03-07 00:00:00'
                obj.reportType = 'CODFODReport'
                obj.orderStatus = null
                obj.isAmazon = false
                obj.all = true
                obj.jobKesByPage = this.condition
            }else{
                obj.from = this.condition.shipmentDateFrom
                obj.to = this.condition.shipmentDateTo
                obj.reportType = 'CODFODReport'
                obj.orderStatus = null
                obj.isAmazon = false
                obj.all = false
                this.condition.trackingIDs = this.trackingNumberList
                obj.jobKesByPage = this.condition
            }
            this.$http.post('/exportReport/exportCODFODList',obj).then(res=> {
                if (res.data.code == '200') {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: res.data.message,
                    icon: 'CheckIcon',
                    variant: 'success',
                    },
                })
                this.$router.push({ name: 'batch-update' })
                }
            })
        },
        handleSelection(val){
            this.selectAll = false
            const arr = []
            val.map(i=>{
                arr.push(i.trackingNumber)
            })
            this.trackingNumberList= arr
        },
        handleSelectAll(val) {
            this.selectAll = val ? true : false
        },
         // 默认日期格式转换
        dateFormats() {
            const fromDate = new Date()
            let datetime = fromDate.getFullYear() + "-" +
            (fromDate.getMonth()> 8 ? (fromDate.getMonth() + 1) : "0" + (fromDate.getMonth() + 1)) + "-"
            + (fromDate.getDate()> 9 ? (fromDate.getDate()) : "0" + (fromDate.getDate()))
            + ' ' + '23:59:59'

            const toDate = new Date((new Date).setDate((new Date).getDate() - 2))
            let datetime1 = toDate.getFullYear() + "-" +
            (toDate.getMonth()> 8 ? (toDate.getMonth() + 1) : "0" + (toDate.getMonth() + 1)) + "-"
            + (toDate.getDate()> 9 ? (toDate.getDate()) : "0" + (toDate.getDate()))
            + ' ' + '00:00:00'

            this.condition.shipmentDateFrom = datetime1
            this.condition.shipmentDateTo = datetime
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        handlePageChange(active) {
            this.condition.size = active
            this.getList()
        },
        handleChangePage(page) {
            this.condition.page = page
            this.getList()
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        }
    }
}
</script>
<style scoped>
.ll-page{
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.ll-fpickr {
  padding-left: 1rem;
  padding-right: 1rem;
}
.ll-searchBtn div{
  background-color: #ed6d01;
  color: #fff;
  width: 60px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ll-searchBtn div:active{
  opacity: 0.8;
}
.ex-bt{
    height: 35px;
    margin-top: 10px;
}
.ll-right{
    margin-right: 10px;
    margin-top: 10px;
}
</style>
<style >
[dir] .input-group:not(.bootstrap-touchspin):focus-within .form-control,
[dir] .input-group:not(.bootstrap-touchspin):focus-within .input-group-text,
[dir] .input-group:not(.bootstrap-touchspin):focus-within .custom-select{
  border-color: #ed6d01;
  box-shadow: none;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
[dir] .vgt-table.bordered th{
  padding: 10px;
  font-size: 12px;
}
table.vgt-table{
  font-size: 12px;
}
[dir] .table.b-table > tbody .b-table-row-selected.table-active td{
  background-color: unset;
  background: unset;
}
[dir] .table.b-table.b-table-selectable:not(.b-table-selectable-no-click) > tbody > tr{
  background-color: unset;
  background: unset;
}
</style>